import classNames from 'classnames';
import React, { FC, useContext, useState } from 'react';

import Nav from '../../Nav/Nav';
import Wrapper from '../../UI/wrapper/Wrapper';
import { HeaderContext } from '../../providers/headerContextProvider/HeaderContextProvider';
import Logo from '../Logo/Logo';

import styles from './Header.module.scss';

const Header: FC = () => {
    const [isNavActive, setIsNavActive] = useState(false);
    const { isHeaderDark } = useContext(HeaderContext);

    const handleOpenNav = () => {
        setIsNavActive((prev) => !prev);
    };

    return (
        <header
            className={classNames(styles.header, {
                [styles[`colored`]]: isHeaderDark,
            })}>
            <Wrapper className={styles.wrapper}>
                <Logo isHeaderDark={isHeaderDark} />
                <Nav
                    isNavActive={isNavActive}
                    setIsNavActive={setIsNavActive}
                    isHeaderDark={isHeaderDark}
                />
                <button
                    className={classNames(styles.burger, { [styles['burger--open']]: isNavActive })}
                    onClick={handleOpenNav}
                    aria-label="Open and close mobile menu">
                    <span className={styles.burger__line}/>
                    <span className={styles.burger__line}/>
                    <span className={styles.burger__line}/>
                    <span className={styles.burger__line}/>
                </button>
            </Wrapper>
        </header>
    );
};

export default Header;
